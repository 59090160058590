import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import EmailForm from './components/EmailForm/EmailForm';
import UnderConstructionBanner from './components/UnderConstructionBanner/UnderConstructionBanner';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <UnderConstructionBanner />
        
      <EmailForm />
      </header>
    </div>
  );
}

export default App;
