import React from "react";
import "./UnderConstructionBanner.css"; // Importing the CSS file

const UnderConstructionBanner = () => {
  return (
    <div className="banner-container">
      <div className="banner">
        <div className="crane"></div>
        <div className="banner-content">
          <h2>Hive Sport and Social</h2>
          <h1>About Us</h1>
          <p>Hive Sport & Social organizes and manages adult co-ed recreational sports leagues with multiple sports to choose from, that are easy to join and have a casual and fun atmosphere while maintaining a competitive spirit. This is exactly what you are looking for!
Launching in North Kildare in 2025, and coming to an area near you as soon as we can!</p>
          <h2>Website Under Construction</h2>
          <p>We're working hard to bring you a better experience. Stay tuned!</p>
        </div>
      </div>
    </div>
  );
};

export default UnderConstructionBanner;