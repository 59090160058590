import React, { useState, useCallback } from 'react';
import axios from 'axios';
import { ColorRing } from 'react-loader-spinner'

const EmailForm = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    console.log('Email submitted:', email);

    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(email)) {
      setError('Please enter a valid email address.');
      return;
    }

    setLoading(true); // Set loading to true when form is submitted

    try {
      const response = await axios.post(
      'https://bf6ithravj.execute-api.eu-west-1.amazonaws.com/prod/emails',
      { "email": email },
      {
        headers: {
        'Content-Type': 'application/json',
        'x-api-key': 'ogPHVx45jLcCUwZj9tMT7z55HkxeFyP6qDUMpyYd'
        }
      }
      );
      console.log('Response:', response.data);
      setError(''); // Clear any previous error messages
    } catch (error) {
      console.error('Error submitting email:', error);
      setError('Error submitting email. Please try again.');
    } finally {
      setLoading(false); // Set loading to false after response is received
      setSubmitted(true); 
    }

    setEmail('');
  }, [email]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label htmlFor="email">Submit your email address if you are interested in learning more:</label>
        <input
          type="email"
          id="email"
          className="form-control"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </div>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {loading ? (
        <ColorRing
          visible={true}
          height="80"
          width="80"
          ariaLabel="color-ring-loading"
          wrapperStyle={{}}
          wrapperClass="color-ring-wrapper"
          colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
          />
      )  : submitted ? (
        <p>Thanks for submitting your email!</p>
      ) : (
        <button type="submit" className="btn btn-primary">Submit</button>
      )}
    </form>
  );
};

export default EmailForm;